<template>
  <v-app id="inspire" style="background-color: #dcebff">
    <div
      style="width: 100vw"
      class="header l-section l-section--slanted mt-n16"
    >
      <div
        class="
          d-flex
          flex-row
          justify-space-between
          align-center align-self-center
          mx-auto
          l-section__content
        "
        :style="$vuetify.breakpoint.smAndDown ? 'width: 90%' : 'width: 80%'"
      >
        <v-img
          src="../assets/Logo/SVG/WhiteYellow.svg"
          :max-width="$vuetify.breakpoint.xsOnly ? 100 : 180"
          class="has-show ml-3 mt-n1 mt-md-n3"
          eager
          @click="toHome()"
        ></v-img>

        <!-- <v-btn
          color="darkpink"
          dark
          @click="toLogin()"
          class="px-5 px-sm-8 py-5 has-show rounded-lg"
        >
          {{ $t('home.home_login') }}
          <v-icon right>mdi-login</v-icon>
        </v-btn> -->
      </div>
    </div>
    <v-row class="pa-8">
      <v-col cols="6" sm="4" md="3" lg="2" v-for="file in files" :key="file.id" >
        <v-card
          class="rounded-xl d-flex flex-column justify-space-between"
          elevation="4"
          height="300"
          target="_blank"
          :href="file.url"
        >
          <v-card-title class="py-6">
            <v-icon x-large :color="file.color">{{ file.icon }}</v-icon>
            <v-spacer></v-spacer>
            <v-btn
            style="height: 34px;padding: 0px 6px;min-width: 0px;"
            dark
            color="darkblue"
            rounded
          >
            <v-icon> mdi-download </v-icon>
          </v-btn>
            <!-- <v-icon>mdi-dots-vertical</v-icon> -->
          </v-card-title>
          <v-card-text class="mt-6">
            <div
              class="text-subtitle-2 text-sm-h6 black--text font-weight-bold"
            >
              {{ file.name }}
            </div>
            <div
              class="font-weight-medium"
              style="font-size: 0.8rem"
              v-text="file.updatedAt"
            />
          </v-card-text>
          <v-card-actions class="grey lighten-3 pa-4">
            <span
              v-text="file.size"
              class="font-weight-medium"
              style="font-size: 0.9rem"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  
    <v-footer dark padless class="mt-6 mt-md-16">
      <v-card flat tile style="width: 100%">
        <v-row class="darkblue px-4" no-gutters>
          <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
            <v-img
              src="../assets/StarMoon.svg"
              max-width="120"
              class="float-left"
              style="transform: scale(0.9)"
            />
          </v-col>

          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-col cols="4" sm="3" md="2">
            <v-img
              src="../assets/Logo/SVG/WhiteYellow.svg"
              :max-width="$vuetify.breakpoint.xsOnly ? 120 : 150"
              class="mx-auto my-6 my-md-4"
            ></v-img>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="6"
            lg="5"
            class="text-end d-flex"
            :class="
              $vuetify.breakpoint.smAndDown
                ? ' flex-column'
                : 'justify-space-between'
            "
            align-self="center"
            style="opacity: 0.9"
          >
            <div>
              <a
                href="./faq"
                target="_blank"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('home.home_duvidas') }}
              </a>
            </div>

            <v-divider
              color="white"
              vertical
              v-if="$vuetify.breakpoint.mdAndUp"
            ></v-divider>
            <div>
              <a
                href="/privacypolicy"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('home.home_termPriv') }}
              </a>
            </div>

            <v-divider
              color="#ffffff"
              vertical
              v-if="$vuetify.breakpoint.mdAndUp"
            ></v-divider>

            <div>
              <v-icon small>mdi-whatsapp</v-icon>
              <a
                href="https://wa.me/551935736050"
                target="_blank"
                class="
                  white--text
                  font-weight-regular
                  text-caption
                  my-1 my-md-0
                "
              >
                {{ $t('home.home_suporte') }}
              </a>
            </div>
          </v-col>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
            <v-img
              src="../assets/Spaceship.svg"
              max-width="120"
              class="float-right"
              style="transform: scale(0.9)"
            />
          </v-col>
        </v-row>

        <v-card-text
          class="py-2 white--text text-center"
          style="background-color: #0d3d70"
        >
          {{ new Date().getFullYear() }} -
          <strong>{{ $t('home.home_respostaDaPergunta.resposta2') }}</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
// const axios = require("axios");
// const url = process.env.VUE_APP_API_URL;
// const token = "Baerer " + localStorage.getItem("accessToken");
//const TermsUse = require("./TermsUse.js");

export default {
  data() {
    return {
      content: [],
      colors: ["yellow", "pink", "blue"],
      files: [
        {
          id: 1,
          url: "https://makereducacional.com.br/Downloads/Maker%20Create/MRTSetup.zip",
          name: "Maker Create",
          updatedAt: "25/06/2024",
          size: "247 mb",
          icon: "mdi-file",
          color: 'darkblue'
        },
        {
          id: 2,
          url: "https://makereducacional.com.br/Downloads/Maker%20Skill/whalesbot_block_studio_v2.3.0.exe",
          name: "Maker Skill",
          updatedAt: "25/06/2024",
          size: "150 mb",
          icon: "mdi-file",
          color: 'darkblue'
        },
        {
          id: 3,
          url: "https://makereducacional.com.br/Downloads/Maker%20Skill%20OBR/AI%20Module_Setup_v1.6.12.exe",
          name: "Maker Skill OBR",
          updatedAt: "25/06/2024",
          size: "566 mb",
          icon: "mdi-file",
          color: 'darkblue'
        },
        {
          id: 4,
          url: "https://makereducacional.com.br/Downloads/Maker%20Discovery/MRT-X%20Setup%20v3.0.1626.exe",
          name: "Maker Discovery",
          updatedAt: "25/06/2024",
          size: "38 mb",
          icon: "mdi-file",
          color: 'darkblue'
        },
        {
          id: 5,
          url: "https://play.google.com/store/apps/details?id=com.makerrobotics.makerstudio",
          name: "Maker Studio",
          updatedAt: "27/06/2024",
          size: "19 mb",
          icon: "mdi-google-play",
          color: 'darkblue'
        },
        {
          id: 6,
          url: "https://play.google.com/store/apps/details?id=com.whalesbot.games&hl=pt_BR",
          name: "Maker Skill",
          updatedAt: "26/06/2024",
          size: "262 mb",
          icon: "mdi-google-play",
          color: 'darkblue'
        },
        {
          id: 7,
          url: "https://apps.apple.com/br/app/whalesbot/id1440552301",
          name: "Maker Skill",
          updatedAt: "26/06/2024",
          size: "307 mb",
          icon: "mdi-apple",
          color: 'darkblue'
        },
        {
          id: 8,
          url: "https://downloads.arduino.cc/arduino-1.8.19-windows.exe",
          name: "Arduino IDE",
          updatedAt: "28/03/2025",
          size: "112 mb",
          icon: "mdi-file",
          color: 'darkblue'
        },
        {
          id: 9,
          url: "https://makereducacional.com.br/Downloads/Arduino%20Bloco/S4A16.zip",
          name: "S4A",
          updatedAt: "28/03/2025",
          size: "28 mb",
          icon: "mdi-file",
          color: 'darkblue'
        },
      ]
    };
  },
  mounted() {
    document.title = this.$t('home.home_titlesoftware');
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    toLogin() {
      this.$router.push("/login");
    },
    checkIndex(index) {
      let order = [1, 2];

      let position = (index + 1) % 2;
      let res = order[position];
      return res;
    },
    colorLook(index) {
      let position = (index + 1) % 3;
      let color = this.colors[position];
      return color;
    },
  },
};
</script>

<style lang="scss" scoped>
.l-section {
  transform: skewY(1deg);
}

.l-section__content {
  transform: skewY(-1deg);
}

.text-div {
  font-size: 1.1rem;
  text-align: justify;
}

.custom-dialog {
  align-self: flex-end;
}

.header {
  background-image: linear-gradient(
    to bottom,
    #0c2f55 0,
    #0c2f55 70%,
    #0d3d70 30%
  );
  background-size: cover;
  width: 100%;
  min-height: 35vh;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

@media (max-width: 959px) {
  .header {
    min-height: 23vh; /* Update the min-height value for small screens */
  }
}

.barra {
  width: 65%;
  height: 2px;
  margin: 0 auto;
  border-radius: 1rem;
  border: 0px;
}

.image-border {
  border: solid 5px #197cff;
  border-radius: 5px;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.404));
  transition: all 200ms;

  &:hover {
    border: solid 5px #0e4887;
    transform: rotate3d(1, 1, 1, 15deg) scale(1.1);
  }
}

.has-show {
  animation: has-show 2s cubic-bezier(0, 0.55, 0.45, 1) forwards;
}

@keyframes has-show {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.text-pink {
  color: #fd80cd;
  -webkit-text-stroke: 1px #e245a6;
}

.text-yellow {
  color: #f9c228;
  -webkit-text-stroke: 1px #cf9c0e;
}

.text-blue {
  color: #1670e8;
  -webkit-text-stroke: 1px #0e4887;
}

a {
  &:link {
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

.scale-up-center:hover {
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes scale-up-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.buttonDel {
  height: 34px;
  padding: 0px 6px;
}


</style>